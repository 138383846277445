export class UserDetails {
    role = "";
    companyId = "";
    username = "";
    uniqueID = "";
    teams: Team[] = [];
}

export class Team {
    teamId = "";
    channel = "";
    name = "";
}

export class Member {
    name = "";
    email = "";
    teams: string[] = [];
    uniqueId = "";
    allowReporting = false;
    isOffPlatform = false;
}

export class Stamp {
    bureauMarket = "";
    bureauMarketCode = "";
    bureauSubMarket = "";
    businessUnit = "";
    stampType = "";
    uniqueID = "";
}

export class Corporate {
    _id = "";
    companyId = "";
    role = "";
    name = "";    
    admins: string[] = [];
    members: Member[] = [];
    teams: Team[] = [];
    stamps: Stamp[] = [];
}

export const Session = {
    userDetails : new UserDetails(),
    corporate : new Corporate()
}

